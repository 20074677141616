import { Splide, SplideSlide } from "@splidejs/react-splide"
import "./splide-thubnail.css"
import { useState, useEffect } from "react"
const Slider = ({ data, current, clickId }) => {
	const opt = {
		// fixedWidth: 100,
		isNavigation: true,
		rewind: true,
		pagination: false,
		perPage: 4,
	}
	const [index, setIndex] = useState(0)
	const clickSlide = (item) => {
		setIndex(item.index)
		current(item.index)
	}

	useEffect(() => {
		if (clickId !== 'undefined') {
			setIndex(clickId)
		}
	}, [clickId])

	return (
		<>
			{data.length > 0 && (
				<div className="spslide_thumb_main">
					<div className="single_pro_img">
						<img className="img-fluid" src={data[index].url} alt="" />
					</div>
					{data.length > 1 && (
						<Splide options={opt} onClick={clickSlide} onMounted={clickSlide} onArrowsUpdated={clickSlide} onDrag={clickSlide} onScroll={clickSlide}>
							{data.map((bn) => {
								return (
									<SplideSlide>
										<img className="img-fluid single_product_thumb" src={bn.url} alt="" />
									</SplideSlide>
								)
							})}
						</Splide>
					)}
				</div>
			)}
		</>
	)
}
export default Slider
