import CarouselAll from "../../components/carousel/all-carousel"
import { SplideSlide } from "@splidejs/react-splide"
import { Link } from "react-router-dom"
import { toUrl } from "../../helpers/functions"

import './categories.css'
const Categories = ({ data }) => {
	return (
		<>
			<CarouselAll
				title="Categories"
				page={6}
				link="/category"
				className={"categoey"}
				options={{
					1199: {
						perPage: 4,
					},
					939: {
						perPage: 3,
					},
					767: {
						perPage: 1,
					},
				}}
			>
				{data.map((item) => {
					return (
						<SplideSlide>
							<Link to={"/category/" + toUrl(item.category)} id={"category-" + item.id}>
								<div className="slide_box_cat">
									<div className="cat_img_wrapper">
										<div className="img" style={{ backgroundImage: `url('${item.image ? item.image : process.env.REACT_APP_CATEGORY_IMG}')` }}>
										</div>
									</div>
									<div className="title">
										<h3>{item.category}</h3>
									</div>

								</div>
							</Link>
						</SplideSlide>
					)
				})}
			</CarouselAll>
		</>
	)
}

export default Categories
