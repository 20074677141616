import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import FeedPost from "../../components/social/post/admin_post"
import { url } from "../../helpers/url"
import { Context } from "../../helpers/context"
import { useParams, useHistory } from "react-router-dom"

const SingleSocialPost = (props) => {
  const location = useHistory()
  const { id, userId } = useParams()

  const [post, setPost] = useState([])
  const [user, setUser] = useState()
  const [load, setLoad] = useState(false)
  const [status, setStatus] = useState(false)
  const [conUser, setConUser] = useContext(Context)


  useEffect(() => {
    if (location.location.search === "?share=true") {
      console.log("data")
      console.log(location)
      axios.post(`${url}/influencer/post/share/view/${id}/${userId}/api`).then(({ data }) => {
        console.log(data)
      })
      location.push(location.location.pathname)
    }
  }, [])

  useEffect(() => {
    axios.get(`${url}/influencer/feed/api?type=one&id=${id}page=0&userId=${userId}`).then(({ data }) => {
      if (data.success) {
        setPost(data.rows)
        setUser(data.user)
        setStatus(data.friend)
        setLoad(true)
      }
    })
  }, [id])

  return (
    <>
      <FeedPost route={"influencer"} type="single" data={post} isUser={user} isLoad={load} isStatus={status} />
    </>
  )
}
export default SingleSocialPost
