import React, { useState, useEffect, useContext, useRef } from "react"
import axios from "axios"
import "./star.css"
import { url } from "../../helpers/url"
import { Context } from "../../helpers/context"
import { getToday } from "../../helpers/time"
import ReactStars from "react-rating-stars-component"
import Loader from "../../helpers/load"
import Pagination from "../pagination/pagination"
import { dynamicHTML } from "../../helpers/dynamic_html"

import { username } from "../../helpers/functions"
import { Link } from "react-router-dom"


const Star = ({ reviews, currentReviews, single, click, type, count }) => {
	const [rating, setRating] = useState([])
	const [starCount, setStarCount] = useState([])
	const [currentRate, setcurrentRate] = useState({})
	const [value, setValue] = useState(0)
	const [user, setUser] = useContext(Context)
	const [load, setLoad] = useState(false)
	const [product, setProduct] = useState({})
	useEffect(() => {
		setRating(reviews)
		setStarCount(count)
		setcurrentRate(currentReviews)
		setProduct(single)
	}, [currentReviews])


	// On Type comments
	const [content, setContent] = useState("")
	const [dbimage, setDbimage] = useState("")
	const [fileType, setFileType] = useState("")
	const blankRef = useRef(null)
	const starHandler = (value) => {
		setValue(value)
	}

	const onType = (e) => {
		setContent(e.target.value)
	}
	const onBlankClick = (e) => {
		blankRef.current.click()
	}
	const deleteImg = (i) => {
		if (i) {
			let img = i.replace(url + "/uploads/", "")
			axios.post(url + "/admin/media/api", {
				mode: 'delete',
				image: img,
				type: 'sinlge'
			})
			setDbimage("")
		}

	}

	const [disabled, setDisabled] = useState(false)
	const imageHandle = (e) => {
		const formData = new FormData()
		setDisabled(true)
		formData.append("image", e.target.files[0])
		formData.append("type", "product")
		e.target.value = ""
		axios.post(url + "/uploads", formData).then(({ data }) => {
			if (data.success) {
				const type = data.type.split('/')[0]
				setFileType(data.type.split('/')[0])
				if (type === 'video') {
					setDbimage(url + '/uploads/video/' + data.filename)
				} else {
					setDbimage(url + '/uploads/' + data.filename)
				}
				setDisabled(false)
			}
		})
	}


	const starController = {
		edit: false,
		half: true,
		count: 5,
		size: 22,
		isHalf: true,
		activeColor: '#F68924',
		color: '#C4C4C4'
	}
	const [showReview, setShowReview] = useState(false)
	const showReviewForm = () => {
		if (user.type) {
			setShowReview(true)
		} else {
			setUser({ ...user, loginPopup: true, pageYOffset: window.pageYOffset })
		}
	}


	function getPaginationId(id) {
		const page = id > 0 ? (id - 1) * 10 : 0
		axios.get(`${url}/star/${type}/api?page=${page}&id=${product.id}`)
			.then(({ data }) => {
				setRating(data.rows);
				setStarCount(data.count);
			})
	}


	const writeReviw = () => {

		if (!currentRate.userId && type === "product") {
			const formData = {
				total: value,
				productId: product.id,
				comment: content,
				image: dbimage,
				type: fileType,
				businessId: product.businessId,
				url: product.url,
				title: product.title,
				mainUserId: product.userId
			}
			// setLoad(true)
			axios.post(`${url}/star/strain/api`, formData).then(({ data }) => {
				if (data.success) {
					setLoad(false)
					setcurrentRate(data.currentReview)
					setProduct(data.product)
					setRating([...rating, ...data.rating])
					click(data.product)

					if (data.notification) {
						user.socket.emit("addNotification", data.notification)
					}
				}
			})
		}
		if (!currentRate.userId && type === "business") {
			const formData = {
				total: value,
				businessId: product.id,
				comment: content,
				image: dbimage,
				type: fileType,
				url: product.url,
				title: product.title,
				mainUserId: product.userId
			}

			// setLoad(true)
			axios.post(`${url}/star/dispensary/api`, formData).then(({ data }) => {
				if (data.success) {
					setLoad(false)
					setcurrentRate(data.currentReview)
					setProduct(data.product)
					setRating([...rating, ...data.rating])
					click(data.product)

					if (data.notification) {
						user.socket.emit("addNotification", data.notification)
					}
				}
			})
		}
	}


	return (
		<div className="star_main">
			{load && <Loader />}
			<div className="business_review ">
				{rating.map((item) => {
					return (
						<div className="review_box">
							<div className="user_name">
								<p className="green my-2"><Link to={"/profile/" + item.userId}>{username(item["registers.fname"], item["registers.lname"])}</Link></p>
							</div>
							<div className="root_star">
								<ReactStars  {...starController} classNames={"left"} value={item.total} />
								<span className="star_span single_star">{getToday(item.createdAt)}</span>
							</div>

							<div className="comment">
								<p>{dynamicHTML(item.comment)}</p>
							</div>
							{item.image && item.type === 'image' &&
								<div className="review_img_box"><img src={item.image} alt="" /></div>
							}
							{(item.image && item.type === 'video') &&
								<div className="review_img_box"><video controls="true" src={item.image}></video></div>
							}
						</div>
					)
				})}

				{!currentRate.userId &&
					<div className="star_left">
						<a className="write_review" onClick={showReviewForm}>
							<img src={url + '/uploads/default/review.png'} alt="" />
							Write a Review
						</a>
					</div>
				}

				{/* {(product.review > 0 && product.total > 0) && ( */}

				{(!currentRate.userId && !load && showReview) && (
					<>
						<p> {type === "businesss" ? "Rate this Business" : "Rate this Product"}</p>
						<ReactStars color="#c4c4c4" activeColor="#F68924" classNames={"left"} count={5} size={34} onChange={starHandler} value={value} />

						<div className="comment">
							<textarea onChange={onType} name="comment" rows="4" value={content} />
						</div>
						<div className="comment_file_box title_color">
							<label htmlFor="">Upload images/videos</label>
						</div>
						<div className="comment_file_box title_color mb-3">
							<input onChange={imageHandle} ref={blankRef} className="form-control d-none" id="validationCustom1" type="file" name="image" />

							<div className="upload_images">
								{(dbimage && fileType === 'image') &&
									<div className="upload_image" style={{
										backgroundImage: `url('${dbimage}')`
									}}>
										<img onClick={() => deleteImg(dbimage)} src={url + "/uploads/default/admin_delete2.png"} alt="" />
									</div>
								}
								{(dbimage && fileType === 'video') &&
									<div className="upload_image video">
										<video controls="true" src={dbimage}></video>
										<img onClick={() => deleteImg(dbimage)} src={url + "/uploads/default/admin_delete2.png"} alt="" />
									</div>
								}
								{!dbimage &&
									<div onClick={onBlankClick} className="upload_image upload_blank_image">
										<div className="blank_items">
											<i className="fa fa-image"></i>
											<p className="add_photo">+ Add Photo/Video</p>
										</div>
									</div>
								}
							</div>
						</div>
						<a onClick={writeReviw} className={`btn btn-solid no_animate w-auto reviewbtn ${(value && !disabled) ? "" : "disabled"}`}>
							Rate It
						</a>
					</>
				)}
			</div>

			{count.length > 0 && <Pagination product={rating.length} count={starCount} clickId={getPaginationId} />}

		</div>
	)
}
export default Star
