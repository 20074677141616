import { Splide, SplideSlide } from "@splidejs/react-splide"
import "./spslide.css"
import { Link } from "react-router-dom"
const Slider = ({ slides }) => {
	return (
		<div className="spslide_main">
			<div className="container">
				<Splide options={{ rewind: true }} aria-label="React Splide Example">
					{slides.map((item) => {
						return (
							<SplideSlide>
								<div className="slide_flex row">
									<div className="box_left col-6 m-auto">
										<div className="box_left_contain">
											<h2>{item.title}</h2>
											<p>{item.description}</p>
											<div className="explore_main">
												<Link to={item.url ? item.url.replace("https://letsmango.com", '') : ''} className="global_btn">
													{item.button}
												</Link>
											</div>
										</div>
									</div>
									<div className="box_right col-6" style={{
										backgroundImage: `url("${item.image}")`
									}}>
										{/* <div className="box_right col-6" style={{
									backgroundImage: `url("${url}/uploads/default/banner_bg.png")`
								}}> */}
									</div>
								</div>
							</SplideSlide>
						)
					})}
				</Splide>
			</div>
		</div>
	)
}
export default Slider
