import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import { url } from "../../helpers/url"
import Loader from "../../helpers/load"
import './writer.css'
import { Context } from "../../helpers/context"
import Autocomplete from "react-google-autocomplete"
const key = process.env.REACT_APP_MAP_KEY
const Registration = ({ userType, title, button, api }) => {
  const [type, setType] = useState(userType)
  const [user, setUser] = useContext(Context)



  useEffect(() => {
    return () => {
      if (typeof window !== "undefined" && user.type) {
        console.log("doubble")
        const currentPage = localStorage.getItem("page") ? localStorage.getItem("page") : "/"
        window.location.href = currentPage
      }

    }
  }, [])
  const [value, setValue] = useState({
    fname: "",
    lname: "",
    email: "",
    address: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    description: "",
    emailEx: "",
    success: false,
  })
  const onType = (id, e) => {
    let updateState = {
      ...value,
      [id]: e.target.value,
      emailEx: false,
    }
    setValue(updateState)
  }
  const [term, setTerm] = useState(false)
  const onCheck = () => {
    setTerm(!term)
  }

  const [load, setLoad] = useState(false)
  const [none, setNone] = useState("block")
  const [isEmail, setIsEmail] = useState(false)



  const addDiv = () => {
    var addressInputElement = document.querySelector(".user_address_row")
    var pacContainer = document.querySelector(".pac-container")
    if (addressInputElement && pacContainer) {
      addressInputElement.appendChild(pacContainer)
    }
  }


  const [city, setCity] = useState()
  const [zip, setZip] = useState()
  const [state, setState] = useState()


  const [currentPlace, setCurrentPlace] = useState("")
  const [cor, setCor] = useState([])
  const addressChanger = (e) => {
    const ad = e.target.value
    setCurrentPlace(ad)
  }



  const placeController = (place) => {
    let address
    let zip = "";
    let city;
    let state;
    let number;

    place.address_components.map(item => {
      // console.log("item")
      // console.log(item)
      if (item.types[0] === "route") address = item.long_name
      if (item.types[0] === "street_number") number = item.long_name
      if (item.types[0] === "postal_code") zip = item.short_name
      if (item.types[0] === "administrative_area_level_3" || item.types[0] === "locality") city = item.long_name
      if (item.types[0] === "administrative_area_level_1") state = item.short_name
    })

    if (number) {
      address = number + " " + address
    }

    setCurrentPlace(address)
    setZip(zip)
    setCity(city)
    setState(state)

  }



  const formValue = (e) => {
    e.preventDefault()
    if (value.email && value.email.includes('.com') && value.email.includes('@')) {
      setIsEmail(false)
      axios.post(`${url}${api}`, { ...value, city: city, zip: zip, state: state, address: currentPlace, mode: 'add', type: userType }).then((data) => {
        const result = data.data
        const updateState = {
          emailEx: result.email,
          business: result.business,
          success: result.success,
        }
        setValue({ ...value, ...updateState })
        setLoad(false)
        setNone("block")
      })
    } else {
      setIsEmail(true)
    }
  }


  return (
    <>
      {load && <Loader />}
      <section className={"login-page section-b-space " + none}>
        <div className="container registration_container">

          <div className={"row registration boxshadow business"}>
            {value.success ? <div className="alert alert-success">
              <p>Please confirm your email to process verification.</p>
            </div>
              : (
                <>

                  <div className={"scecond col-lg-12"}>
                    <h3 className="mb-3 title_color">{title}</h3>

                    <div className="theme-card">
                      <form className="" method="post" onSubmit={formValue}>

                        <div className="input_group">
                          <label htmlFor="fname">First Name</label>
                          <input required name="fname" onChange={(e) => onType("fname", e)} type="text" className="form-control mb-3" />
                        </div>

                        <div className="input_group">
                          <label htmlFor="lname">Last Name</label>
                          <input required name="lname" onChange={(e) => onType("lname", e)} type="text" className="form-control mb-3" />
                        </div>

                        <div className="input_group">
                          <label htmlFor="email">Email</label>
                          <input required name="email" onChange={(e) => onType("email", e)} type="email" className="form-control mb-3" />
                          {value.emailEx ? <p className="alert alert-danger">This email already exist on our records</p> : ""}
                          {isEmail && <p className="alert alert-danger">This is not a valid email.</p>}
                        </div>

                        <div className="input_group">
                          <label htmlFor="address">Address</label>

                          <Autocomplete
                            onFocus={addDiv}
                            className="form-control"
                            options={{
                              // types: ['geocode',],
                              // (regions)
                              // 'address'
                              types: ["address"],
                              componentRestrictions: { country: "us" },
                            }}
                            apiKey={key}
                            onPlaceSelected={placeController}
                            onChange={addressChanger}
                            value={currentPlace}
                            placeholder="Address"
                          />
                        </div>

                        <div className="input_group">
                          <label htmlFor="city">City</label>
                          <input name="city" onChange={(e) => setCity(e.target.value)} type="text" className="form-control mb-3" value={city} />
                        </div>

                        <div className="input_group">
                          <label htmlFor="state">State/ Province</label>
                          <input name="state" onChange={(e) => setState(e.target.value)} type="text" className="form-control mb-3" value={state} />
                        </div>

                        <div className="input_group">
                          <label htmlFor="zip">Zip Code</label>
                          <input name="zip" onChange={(e) => setZip(e.target.value)} type="text" className="form-control mb-3" value={zip} />
                        </div>

                        <div className="input_group">
                          <label htmlFor="phone">Phone</label>
                          <input name="phone" onChange={(e) => onType("phone", e)} type="text" className="form-control mb-3" />
                        </div>
                        <div className="input_group textarea">
                          <label htmlFor="phone">Want to say anything?</label>
                          <textarea name="description" onChange={(e) => onType("description", e)} type="text" className="form-control mb-3" />
                        </div>

                        <div className="form-group terms">
                          <div className="check_all">
                            <div className="check">
                              <input type="checkbox" onChange={onCheck} checked={term} />
                            </div>
                            <div className="text">
                              <label htmlFor="">
                                <a href="/terms" target="_blank">Terms & Conditions</a>
                              </label>
                            </div>
                          </div>
                        </div>

                        {(value.emailEx || !term) ? (
                          <button disabled className="btn input_button" type="submit">
                            {button}
                          </button>
                        ) : (
                          <button className="btn input_button" type="submit">
                            {button}
                          </button>
                        )}
                      </form>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </section>
    </>
  )
}
export default Registration
